/**
 * 员工消耗次数汇总
 */

 import * as API from '@/api/index'

 export default {
     // 获取列表
     getDataList:params => {
         return API.POST('api/employeeTreatNumberSummary/list',params)
     },
     // 导出
     getDownloadExcel:params => {
         return API.exportExcel('api/employeeTreatNumberSummary/excel',params)
     }
 }