<template>

<div class="content_body" v-loading="loading">
      <div class="nav_header" style="padding:0px">
        <el-form :inline="true" size="small" :model="searchlData" @submit.native.prevent>
          <el-form-item label="门店">
            <el-select v-model="searchlData.Entity" clearable filterable placeholder="请选择门店"
              :default-first-option="true" @change="handleSearch">
              <el-option v-for="item in storeEntityList" :label="item.EntityName" :value="item.ID" :key="item.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="员工查找">
            <el-input v-model="searchlData.EmployeeName" clearable @keyup.enter.native="handleSearch" @clear="handleSearch" placeholder="输入员工名称搜索"></el-input>
          </el-form-item>
          <el-form-item label="职务">
            <el-select v-model="searchlData.JobID" clearable filterable placeholder="请选择职务"
              :default-first-option="true" @change="handleSearch">
              <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间筛选">
              <el-date-picker v-model="searchlData.QueryDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="handleSearch" @clear="handleSearch">
          </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-prevent-click @click="handleSearch">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-prevent-click @click="downloadExcel" :loading="DownloadLoding">导出</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="table.tableData" show-summary :summary-method="summaryMethod">
          <el-table-column prop="EntityName" label="业绩门店"></el-table-column>
          <el-table-column prop="EmployeeName" label="员工姓名"></el-table-column>
          <el-table-column prop="JobName" label="员工职务"></el-table-column>
          <el-table-column prop="TimeCardQuantity" label="时效卡消耗次数(不含减重)"></el-table-column>
          <el-table-column prop="GeneralCardQuantity" label="通用次卡消耗次数"></el-table-column>
          <el-table-column prop="ProjectQuantity" label="项目消耗次数"></el-table-column>
          <el-table-column prop="SavingCardQuantity" label="储值卡消耗项目次数"></el-table-column>
          <el-table-column prop="Total" label="合计"></el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pad_15 text_right">
        <el-pagination background v-if="Paginations.total > 0" @current-change="handleChangePage" :current-page.sync="Paginations.page" :page-size="Paginations.page_size" :layout="Paginations.layout" :total="Paginations.total"></el-pagination>
      </div>

</div>
</template>
<script>
import API from "@/api/Report/Employee/salePerformanceCommissionDetailStatement";
import APIToo from "@/api/Report/Employee/treatNumberSummary";
import EntityAPI from "@/api/Report/Common/entity";
export default {
    data(){
        return {
          loading:false, 
            jobTypeData:[], // 职务
            storeEntityList:[], // 门店
            searchlData:{
                Entity:'', // 门店
                EmployeeName:'', // 员工
                JobID:'', // 职务ID
                QueryDate:[new Date(),new Date()] // 时间
            },
            table:{
              tableData:[], // 表格
              tableFormList:{}, // 合计
            },
            Paginations:{
              page:1,
              page_size:10,
              total:1,
              layout:'total, prev, pager, next,jumper'
            },
            DownloadLoding:false
        }
    },
    methods:{
    // 员工职务
    getJobType: function () {
      var that = this;
      var params = {
        JobTypeName: that.searchlData.EmployeeName,
      };
      API.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 触发搜索
    handleSearch(){
      this.Paginations.page = 1
      this.search()
    },

    // 搜索
    search(){
      var that = this
      if(that.searchlData.QueryDate){
      var params = {
              PageNum:that.Paginations.page,
              EmployeeName:that.searchlData.EmployeeName,
              EntityID:that.searchlData.Entity,
              JobID:that.searchlData.JobID,
              StartDate:that.searchlData.QueryDate[0]?that.searchlData.QueryDate[0]:'',
              EndDate:that.searchlData.QueryDate[1]?that.searchlData.QueryDate[1]:''
            }
          APIToo.getDataList(params).then(res => {
            console.log(res)
            if(res.StateCode == 200){
              that.table.tableData = res.Data.employeeTreatNumberSummaryOutFroms.List
              that.table.tableFormList = res.Data.employeeTreatNumberSummarySumOutFrom
              that.Paginations.page_size = res.Data.employeeTreatNumberSummaryOutFroms.PageSize
              that.Paginations.total = res.Data.employeeTreatNumberSummaryOutFroms.Total
            }else {
              that.$message.error(res.Message)
            }
          })
        }
     
    },

    // 导出
    downloadExcel(){
      var that = this
      var params = {
        PageNum:that.Paginations.page,
        EmployeeName:that.searchlData.EmployeeName,
        EntityID:that.searchlData.Entity,
        JobID:that.searchlData.JobID,
        StartDate:that.searchlData.QueryDate[0]?that.searchlData.QueryDate[0]:'',
        EndDate:that.searchlData.QueryDate[1]?that.searchlData.QueryDate[1]:''
      }
      that.DownloadLoding = true
      APIToo.getDownloadExcel(params).then(res => {
         this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement('a')
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = '员工消耗次数汇总.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
      }).finally(() => {
        that.DownloadLoding = false
      })
    },

    // 分页
    handleChangePage(page){
      var that = this
      that.Paginations.page = page
      that.search()
    },

    // 合计
    summaryMethod({columns}){
      const sums = []
      columns.forEach((column,index) => {
        if(index === 0){
          sums[index] = <span class="font_weight_600">合计</span>;
          return
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch(column.property){
          case 'TimeCardQuantity':
            sums[index] = (
              <span class="font_weight_600">{filter_NumFormat(this.table.tableFormList.TimeCardQuantity)}</span>
            )
          break;
          case 'GeneralCardQuantity':
            sums[index] = (
              <span class="font_weight_600">{filter_NumFormat(this.table.tableFormList.GeneralCardQuantity)}</span>
            )
          break;
          case 'ProjectQuantity':
            sums[index] = (
              <span class="font_weight_600">{filter_NumFormat(this.table.tableFormList.ProjectQuantity)}</span>
            )
          break;
          case 'SavingCardQuantity':
            sums[index] = (
              <span class="font_weight_600">{filter_NumFormat(this.table.tableFormList.SavingCardQuantity)}</span>
            )
          break;
          case 'Total':
            sums[index] = (
              <span class="font_weight_600">{filter_NumFormat(this.table.tableFormList.Total)}</span>
            )
          break;
        }
      })

       return sums;
    },

     
    },

    mounted(){
        this.getJobType()
        this.getstoreEntityList()
    },
}
</script>

<style lang="less">

</style>